.Date {
  display: grid;
  gap: 0.4rem;
  color: var(--color-secondary-1);
  text-align: left;

  label {
    span {
      color: var(--color-primary-1);
    }
  }
}

.DropdownFields {
  display: flex;
  flex-direction: row;
}

.SelectDropdownDiv {
  display: flex;
  flex-direction: column;
  margin: 0 2rem 0 0;
}
