.Flex {
  display: flex;
  gap: var(--spacing-1);
  position: relative;

  &.wrap {
    flex-wrap: wrap;
  }
}

.InlineFieldGroup {
  display: flex;
  gap: var(--spacing-2);
  align-items: center;

  & > * {
    flex-grow: 1;
  }

  & > button,
  & > label,
  & > p {
    max-width: max-content;
  }
}

// Direction modifiers.

@mixin create-flex-direction() {
  $directions: (row, column);

  @each $direction in $directions {
    .fd-#{$direction} {
      flex-direction: #{$direction};
    }

    .fd-sm-#{$direction} {
      flex-direction: #{$direction};
    }
  }

  @media only screen and (width >= $tablet-portrait) {
    @each $direction in $directions {
      .fd-md-#{$direction} {
        flex-direction: #{$direction};
      }
    }
  }

  @media only screen and (width >= $tablet-landscape) {
    @each $direction in $directions {
      .fd-lg-#{$direction} {
        flex-direction: #{$direction};
      }
    }
  }

  @media only screen and (width >= $max-width-page) {
    @each $direction in $directions {
      .fd-xl-#{$direction} {
        flex-direction: #{$direction};
      }
    }
  }
}

// Justify-content modifiers.

@mixin create-justify-content() {
  $justifies: (start, center, space-between, end);

  @each $justify in $justifies {
    .jc-#{$justify} {
      justify-content: #{$justify};
    }

    .jc-sm-#{$justify} {
      justify-content: #{$justify};
    }
  }

  @media only screen and (width >= $tablet-portrait) {
    @each $justify in $justifies {
      .jc-md-#{$justify} {
        justify-content: #{$justify};
      }
    }
  }

  @media only screen and (width >= $tablet-landscape) {
    @each $justify in $justifies {
      .jc-lg-#{$justify} {
        justify-content: #{$justify};
      }
    }
  }

  @media only screen and (width >= $max-width-page) {
    @each $justify in $justifies {
      .jc-xl-#{$justify} {
        justify-content: #{$justify};
      }
    }
  }
}

// Align-items modifiers.

@mixin create-align() {
  $aligns: (start, center, end);

  @each $align in $aligns {
    .ai-#{$align} {
      align-items: #{$align};
    }

    .ai-sm-#{$align} {
      align-items: #{$align};
    }
  }

  @media only screen and (width >= $tablet-portrait) {
    @each $align in $aligns {
      .ai-md-#{$align} {
        align-items: #{$align};
      }
    }
  }

  @media only screen and (width >= $tablet-landscape) {
    @each $align in $aligns {
      .ai-lg-#{$align} {
        align-items: #{$align};
      }
    }
  }

  @media only screen and (width >= $max-width-page) {
    @each $align in $aligns {
      .ai-xl-#{$align} {
        align-items: #{$align};
      }
    }
  }
}

@include create-flex-direction();
@include create-justify-content();
@include create-align();
