@import '../../styles/variables';
@import '../../styles/util';

@media only screen and (width <= $size-that-fits-hover-menu) {
  .Menu {
    display: none;
  }
}

@media only screen and (width > $size-that-fits-hover-menu) {
  .Menu {
    margin: 0 auto;
    padding: 0 var(--spacing-2);
    max-width: var(--max-width-page);
    isolation: isolate;
    position: relative;
    z-index: 1;
    font-size: 1.6rem;
    line-height: 2.4rem;
    width: 100%;

    ul {
      list-style: none;
    }

    a {
      text-decoration: none;
      color: var(--color-secondary-1);
      white-space: nowrap;

      &:hover,
      &:focus-visible {
        color: var(--color-primary-1);
      }

      &:hover::before {
        content: '';
        // Prevent hover on surrounding items from collapsing the current submenu too fast.
        position: absolute;
        inset: -1rem;
        z-index: 1;
      }

      &.active {
        font-weight: 700;
      }

      &.expandable::after {
        content: '';
        display: inline-block;
        width: 2.2rem;
        height: 0.7rem;
        background: transparent url(/svg/icon_arrow_pull_down.svg) center right
          no-repeat;
        background-size: contain;
        margin-bottom: 1px;
      }
    }

    li {
      position: relative;

      // Keyboard focus
      &:has(a:focus-visible) {
        & > ul {
          opacity: 1;
          pointer-events: all;
          transform: translateY(0);
        }
      }

      // Also add the same focus styles on hover. This is also
      // a fallback for Firefox which doesn't support :has(...)
      &:hover {
        & > ul {
          opacity: 1;
          pointer-events: all;
          transform: translateY(0);
        }
      }
    }

    > ul {
      display: flex;
      background-color: #fff;
      box-shadow: var(--box-shadow-3);
      border-radius: 4rem;
      padding: 0 1.6rem;

      > li > a {
        padding: 2.1rem 1.6rem 2.2rem 1.6rem;
        display: block;
      }

      // Last menu on the right side, I guess.
      > li:last-child {
        align-self: flex-end;
        margin-inline-start: auto;

        > ul {
          left: auto;
          right: 0;

          // Nested menu's should move to the left.
          ul {
            top: 0;
            left: auto;
            right: calc(100% - 0.8rem);
          }
        }
      }
    }

    ul ul {
      opacity: 0;
      pointer-events: none;
      position: absolute;

      transition:
        opacity 200ms ease-in-out,
        transform 200ms ease-in-out;

      left: 0;
      top: 6.4rem;
      transform: translateY(-0.8rem);

      box-shadow: var(--box-shadow-3);
      border-radius: 1.4rem;
      background-color: #fff;

      // Increase hoverable area
      &::before {
        content: '';
        position: absolute;
        inset: -2rem -3rem -3rem -3rem;
        z-index: -1;
      }

      // Increase hoverable top area for successive dropdowns.
      ul::before {
        inset: -3em;
      }

      li {
        padding: 0 1.6rem;

        &:not(:last-child) a {
          border-bottom: 1px solid #f6f5f2;
        }
      }

      a {
        display: block;
        padding: 1.6rem 0rem;
      }

      ul {
        top: 0;
        left: calc(100% - 0.8rem);
      }
    }
  }
}

@media only screen and (width >= $max-width-page) {
  .Menu {
    padding: 0 var(--spacing-4);
  }
}
