.Html {
  display: grid;
  gap: 2rem;

  .break-all {
    word-break: break-all;
  }

  h2,
  h3 {
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: 500;
  }

  ul,
  ol {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
    list-style-position: outside;
    padding-left: 1.6rem;
  }

  p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    font-weight: 400;
  }

  &.w100 {
    font-weight: 100;
  }

  &.w400 {
    font-weight: 400;
  }

  &.w500 {
    font-weight: 500;
  }

  &.w700 {
    font-weight: 700;
  }

  &.w900 {
    font-weight: 900;
  }

  &.size-large {
    h2,
    h3 {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    dl,
    p,
    li {
      font-size: 1.8rem;
      line-height: 2.7rem;
      font-weight: 200;
    }

    h4,
    strong,
    dt,
    b {
      font-size: 1.8rem;
      line-height: 2.7rem;
      font-weight: 500;
    }

    ul,
    ol {
      padding-left: 2rem;
    }
  }

  &.theme-primary {
    color: var(--color-primary-1);
  }

  &.theme-secondary {
    color: var(--color-secondary-1);
  }
}
