.Icon {
  width: 1.4rem;
  height: 1.4rem;
  color: #fff;
  display: flex;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: #fff;
  flex-shrink: 0;

  /* Colors */
  &.theme-primary {
    background-color: var(--color-primary-1);
  }

  &.theme-secondary {
    background-color: var(--color-secondary-1);
  }

  &.theme-tertiary {
    background-color: var(--color-tertiary-1);
  }

  &.theme-quaternary {
    background-color: var(--color-quaternary-1);
  }

  &.theme-dark {
    background-color: var(--color-dark-1);
  }

  $icons: (
    // cms
    'calendar',
    'facebook',
    'film',
    'headset',
    'heart',
    'instagram',
    'link',
    'linkedin',
    'message',
    'plus',
    'radio',
    'speaker',
    'star',
    'trash',
    'twitter',
    'whatsapp',
    'youtube',
    // hardcoded
    'arrow_back',
    'arrow_forward',
    'arrow_left',
    'arrow_right',
    'cross',
    'email',
    'file',
    'linkedin_alt',
    'list_check',
    'phone',
    'search',
    'sec_back',
    'sec_forward',
    'sliders',
    'speaker_low',
    'speaker_off'
  );

  @each $icon in $icons {
    &.#{$icon} {
      mask-image: url('/svg/icon_#{$icon}.svg');
    }
  }

  &.size-xs {
    width: 1.4rem;
    height: 1.4rem;
  }

  &.size-sm {
    width: 2rem;
    height: 2rem;
  }

  &.size-md {
    width: 3.2rem;
    height: 3.2rem;
  }

  &.size-lg {
    width: 4.4rem;
    height: 4.4rem;
  }
}

.IconBox {
  width: 3.2rem;
  height: 3.2rem;
  background-color: #fce2ce;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
