@import '../../styles/variables';
@import '../../styles/util';

.Main {
  color: #fff;
  background-color: var(--color-secondary-1);
  background: linear-gradient(180deg, #5f7e91, #00506c);
  font-size: 1.4rem;

  a,
  a:hover {
    color: #fff;
  }

  > div {
    margin: 0 auto;
    max-width: var(--max-width-page);
    padding: var(--spacing-4) var(--spacing-2);
  }
}

.Areas {
  display: grid;
  gap: var(--spacing-4);
  align-items: start;
}

@media only screen and (width >= 680px) {
  .Areas {
    grid-template-areas:
      'a b'
      'c c';
    grid-template-columns: 1fr 336px;

    & > *:nth-child(1) {
      grid-area: a;
    }

    & > *:nth-child(2) {
      grid-area: b;
    }

    & > *:nth-child(3) {
      grid-area: c;
    }
  }
}

@media only screen and (width >= $tablet-portrait) {
  .Main > div {
    padding: var(--spacing-6) var(--spacing-3);
  }
}

@media only screen and (width >= $tablet-landscape) {
  .Areas {
    grid-template-areas: 'a c b';
    grid-template-columns: 256px auto 336px;
  }
}

@media only screen and (width >= $max-width-page) {
  .Areas {
    grid-template-columns: 304px auto 336px;
  }

  .Main > div {
    padding: var(--spacing-6) var(--spacing-4);
  }
}

.Legal {
  color: #fff;
  background-color: var(--color-secondary-2);
  font-size: 1.4rem;

  svg {
    display: block;
    height: 4rem;
    width: 100%;

    @media only screen and (width >= $tablet-portrait) {
      height: 4.8rem;
    }
  }

  a {
    color: #fff;
    white-space: nowrap;

    &:hover {
      color: #fff;
    }
  }

  > div {
    margin: 0 auto;
    max-width: var(--max-width-page);
    padding: var(--spacing-2);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: var(--spacing-4);
    flex-direction: row;
    align-items: flex-end;

    @media only screen and (width >= $tablet-portrait) {
      align-items: center;
      padding: var(--spacing-2) var(--spacing-4);
    }
  }

  nav {
    display: flex;
    gap: var(--spacing-2);
    flex-direction: column;

    @media only screen and (width >= $tablet-portrait) {
      flex-direction: row;
      gap: var(--spacing-4);
      align-items: center;
    }
  }
}

.FooterButton {
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  flex: 1;
  padding: var(--spacing-2) var(--spacing-1);
  border-radius: var(--border-radius-1);
  height: 100%;
  color: #fff;

  &:hover {
    color: #fff;
  }

  &.size-large {
    background-color: var(--color-secondary-1);
    border: 0;
    padding: var(--spacing-4) var(--spacing-1);
  }
}
