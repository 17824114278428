.Button {
  --size: 4rem;
  background-color: #fff;
  display: block;
  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  box-shadow: var(--box-shadow-2);
  background-size: var(--size) var(--size);
  text-indent: -9999px;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    inset: 0;
    width: var(--size);
    height: var(--size);
  }

  &.size-large {
    --size: 4.8rem;
  }

  &[aria-current='page'] {
    background-color: var(--color-secondary-1);
  }
}
