.List {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);

  &.spacing-0 {
    gap: 0;
  }

  &.spacing-1 {
    gap: var(--spacing-1);
  }

  &.spacing-2 {
    gap: var(--spacing-2);
  }

  &.spacing-3 {
    gap: var(--spacing-3);
  }

  &.spacing-4 {
    gap: var(--spacing-4);
  }

  &.spacing-5 {
    gap: var(--spacing-5);
  }

  &.spacing-6 {
    gap: var(--spacing-6);
  }
}
