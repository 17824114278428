.ModalOverlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  transition: opacity 200ms ease-in-out;

  &.open {
    opacity: 1;
    pointer-events: all;
  }
}

.ModalContainer {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
  pointer-events: none;
  padding: var(--spacing-5) var(--spacing-2) var(--spacing-2) var(--spacing-2);
  overflow: hidden;

  .Modal {
    background-color: #fff;
    width: 100%;
    max-width: 576px;
    transition:
      opacity 200ms ease-in-out,
      transform 200ms ease-in-out;
    transform: translateY(16rem);
    opacity: 0;
    box-shadow: var(--box-shadow-4);
    border-radius: var(--border-radius-2);
    margin: auto;
    position: relative;
  }

  .ModalBody {
    padding: var(--spacing-2);
    min-height: 12rem;
    position: relative;
    overflow: hidden;
  }

  &.open {
    transform: translateZ(0);
    pointer-events: all;
    overflow: auto;

    .Modal {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.CloseButton {
  position: absolute;
  top: -4rem;
  right: 0.8rem;
  z-index: 1;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    color: #fff;
  }
}
