@import '../../styles/variables';

.StickyPlayer {
  position: sticky;
  left: 0;
  bottom: 0;
  right: 0;
  height: 0;
  pointer-events: none;

  button {
    pointer-events: none;
    background-color: var(--color-primary-1);
    padding: var(--spacing-3) var(--spacing-5);
    color: #fff;
    border-radius: 100vw;
    font-weight: 400;
    font-family: var(--font-family-2);
    text-transform: uppercase;
    font-size: 1.7rem;
    line-height: 1.7rem;
    box-shadow: var(--box-shadow-5);
    position: absolute;
    bottom: var(--spacing-4);
    right: var(--spacing-4);
    display: flex;
    align-items: center;
    gap: var(--spacing-2);
    opacity: 0;
    transform: translateY(200%);
    transition:
      opacity 300ms ease-in-out,
      transform 300ms ease-in-out;

    span {
      margin-top: 2px;
    }
  }
}

@media only screen and (width >= 1600px) {
  .StickyPlayer {
    button {
      pointer-events: all;
      opacity: 1;
      transform: translateY(0%);
    }
  }
}
