@use 'sass:math';
@import '../../styles/variables';

$line-width: 12px;
$line-width-2: ($line-width * 2);
$line-offset: math.hypot($line-width-2, $line-width-2) * -1;

.Button {
  --color: var(--color-primary-1);
  background-color: var(--color);
  border: 1px solid var(--color);
  color: #fff;
  padding: 0 1.6rem 0.2rem 1.6rem;
  min-height: 4rem;
  font-size: 1.4rem;
  line-height: 1.1;
  border-radius: 4rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 0.8rem;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  overflow: hidden;
  isolation: isolate;

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
    color: #fff;
  }

  &.theme-outline,
  &.theme-outline:hover {
    background-color: #fff;
    color: var(--color);
  }

  &:not(.theme-outline, :disabled) {
    box-shadow: var(--box-shadow-2);
  }

  /* Size variations */
  &.size-small {
    min-height: 3.2rem;
    padding: 0 1.4rem 0.2rem 1.4rem;
  }

  &.size-large {
    min-height: 5.6rem;
    padding: 0 1.4rem 0.2rem 1.4rem;
  }

  /* Theme variations */
  &.theme-primary {
    --color: var(--color-primary-1);
  }

  &.theme-secondary {
    --color: var(--color-secondary-1);
  }

  &.theme-tertiary {
    --color: var(--color-tertiary-1);
  }

  &.theme-quaternary {
    --color: var(--color-quaternary-1);
  }

  /* Loading */
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    pointer-events: none;
  }

  &.loading {
    pointer-events: none;

    &::before {
      opacity: 1;
      background: repeating-linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.1) 0px,
        rgba(255, 255, 255, 0.1) $line-width,
        rgba(255, 255, 255, 0) $line-width,
        rgba(255, 255, 255, 0) $line-width-2
      );
      background-repeat: repeat;
      background-attachment: fixed;
      animation: moveLines 500ms linear infinite;
    }
  }

  &.icon-position-right {
    flex-direction: row-reverse;
  }

  @media only screen and (width < $tablet-portrait) {
    &.icon-only-mobile {
      .Label {
        display: none;
      }
    }
  }

  @media only screen and (width < $tablet-landscape) {
    &.icon-only-tablet {
      .Label {
        display: none;
      }
    }
  }
}

@keyframes moveLines {
  from {
    background-position-x: 0;
  }

  to {
    background-position: $line-offset;
  }
}

.ButtonGroup {
  display: flex;
  gap: var(--spacing-2);
}
