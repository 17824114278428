@import 'variables';
@import 'util';

@import 'grid';
@import 'grid-auto';
@import 'gridish';
@import 'flex';
@import 'offset';
@import 'gap';
@import 'min-column-width';

:root {
  // Fonts
  --font-family-1: 'brother-1816', 'Trebuchet MS';
  --font-family-2: 'Grota', 'Helvetica Neue', 'sans-serif';
  --font-family-3: 'Jiffy', 'Helvetica Neue', 'sans-serif';

  // Light
  --color-light-1: #f6f5f2;

  // Primary: Orange
  --color-primary-1: #ea5f04;

  // Secondary: Blue
  --color-secondary-1: #014e67;
  --color-secondary-2: #00435a;

  // Tertiary: Brown
  --color-tertiary-1: #9b3f2a;

  // Quaternary: Teal
  --color-quaternary-1: #42bbbd;

  // Dark
  --color-dark-1: #161616;

  // Box shadow
  --box-shadow-1: 0 0.4rem 2rem -0.7rem rgba(137, 137, 137, 0.5);
  --box-shadow-2: 0 0.1rem 0.5rem -0.2rem rgba(0, 0, 0, 0.5);
  --box-shadow-3: 0 0.4rem 2rem -0.7rem rgba(0, 0, 0, 0.5);
  --box-shadow-4: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
  --box-shadow-5: 1.6rem 1.5rem 3rem -2.5rem rgba(0, 0, 0, 0.5);

  // Border radius
  --border-radius-1: 0.6rem;
  --border-radius-2: 1.5rem;

  // Spacings
  --spacing-1: 0.8rem;
  --spacing-2: 1.6rem;
  --spacing-3: 2.4rem;
  --spacing-4: 3.2rem;
  --spacing-5: 4.8rem;
  --spacing-6: 6.4rem;

  // Plyr
  --plyr-color-main: var(--color-primary-1);

  // Specific sizes
  --max-width-page: #{$max-width-page};
  --side-nav-width: 136px;
  --max-inner-width: 612px;
}

@media only screen and (width > $max-width-page) {
  :root {
    --side-nav-width: 152px;
  }
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Grota';
  src:
    local('Grota'),
    url('/fonts/Grota-black.otf') format('opentype');
  font-display: fallback;
}

@font-face {
  font-family: 'Jiffy';
  src:
    local('Jiffy'),
    url('/fonts/Jiffy.otf') format('opentype');
  font-display: fallback;
}

html,
body {
  font-family:
    var(--font-family-1),
    'Trebuchet MS',
    Helvetica Neue,
    sans-serif;
  line-height: 1.6;
  font-size: 10px; // So you can easily multiply rem * 10 to get to the px value.
  color: var(--color-dark-1);
  font-variant-ligatures: none; // Prevent ligatures in Grota font.
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 1.5rem;
}

.no-scroll,
.no-scroll body {
  overflow: hidden;
}

button {
  all: unset;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

span[role='button'] {
  cursor: pointer;
}

a {
  color: var(--color-primary-1);
  text-decoration: none;
  transition: color 100ms ease-in-out;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

a:hover {
  color: var(--color-tertiary-1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-1);
  font-size: 1.5rem;
}

h1,
h2 {
  font-size: 2.6rem;
  line-height: 2.8rem;
}

h3 {
  line-height: 2.4rem;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
  user-select: none;
}

address {
  font-style: normal;
}

// Get rid of the outline for mouse users but preserve it for keyboard users.
:focus:not(:focus-visible) {
  outline: 0;
}

// Add visible outline for keyboard users only.
@media (hover: hover) {
  :focus-visible {
    outline: 5px solid rgba(241, 147, 84, 0.3);
  }
}

::selection {
  background-color: rgba(241, 147, 84, 0.3);
}

// Header background
html {
  --color-1: #e95902;
  --color-2: #f7a400;
  --alpha: 0.1;
  background-color: var(--color-light-1);

  body::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    transform: skewY(0deg);
    height: 7.2rem;
    background: linear-gradient(
        215deg,
        rgba(255, 255, 255, var(--alpha)) 0%,
        rgba(255, 255, 255, var(--alpha)) 55%,
        rgba(255, 255, 255, 0) 55%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(at top left, var(--color-1), var(--color-2));

    @media only screen and (width >= $tablet-portrait) {
      height: 8.8rem;
    }

    @media only screen and (width >= $tablet-landscape) {
      height: 12.2rem;
    }

    @media only screen and (width > $size-that-fits-hover-menu) {
      height: 13rem;
    }
  }

  &.theme-dark {
    --color-1: #004157;
    --color-2: #1e566c;
    --alpha: 0.05;
  }

  &.theme-light {
    &.theme-skew {
      body::before {
        top: -18rem;
        height: 60rem;
        transform: skewY(-6deg);

        @media only screen and (width >= 768px) {
          height: 52rem;
        }

        @media only screen and (width > 768px) {
          height: clamp(54rem, 63vw, 60rem);
        }
      }
    }
  }
}

// Make footer sticky at the bottom on pages with not enough content.
// This asumes #__next always has exactly 4 children.
#__next {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: 100dvh;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
}

/* Default form styles */

textarea,
input[type='text'],
input[type='email'],
input[type='tel'],
input[type='date'],
input[type='number'] {
  appearance: none;
  background-color: #eee;
  min-height: 4rem;
  text-align: left;
  padding: 0 1.6rem;
  font-size: 1.4rem;
  font-family: inherit;
}

input[type='checkbox'],
input[type='radio'] {
  accent-color: var(--color-secondary-1);
  flex-shrink: 0;

  &:disabled {
    opacity: 0.2;
  }
}

input[type='radio'][disabled] + label {
  color: #aaa;
}

input[type='radio'] + label i {
  color: var(--color-primary-1);
}

// Custom radio button style
input[type='radio'] {
  margin-top: 0.2rem; // Align to first line of text.
  appearance: none;
  width: 1.6rem;
  height: 1.6rem;
  background-color: #fff;
  border: 1px solid var(--color-secondary-1);
  border-radius: 50vw;
  position: relative;

  &:checked {
    &::after {
      content: '';
      position: absolute;
      inset: 3px;
      background-color: var(--color-secondary-1);
      border-radius: 50vw;
    }
  }
}

// Custom checkbox style
input[type='checkbox'] {
  margin-top: 0.5rem; // Align to first line of text.
  appearance: none;
  width: 1.6rem;
  height: 1.6rem;
  background-color: #fff;
  border: 1px solid var(--color-secondary-1);
  border-radius: 0.3rem;

  &:checked {
    background-color: var(--color-secondary-1);
    background-image: url(/svg/icon_check.svg);
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: center;
  }
}

// Custom select style
select {
  appearance: none;
  border: 1px solid #c3c3c3;
  min-height: 4rem;
  text-align: left;
  display: flex;
  border-radius: 4rem;
  font-family: inherit;
  color: var(--color-secondary-1);
  padding: 0 4rem 0.2rem 1.4rem;
  font-size: 1.4rem;
  background: #fff url(/svg/icon_arrow_pull_down.svg) center right 1.6rem
    no-repeat;
  background-size: 1.4rem 0.8rem;
}

label span {
  color: var(--color-primary-1);
}

/* Generic container query container */

div[data-container='inline'] {
  container: content / inline-size;
  display: grid;
}

/* Body modifier for when the mobile menu is opened */

.menu-open,
.menu-open body,
.menu-open #__next {
  overflow: hidden;
  height: 100%;
  position: absolute;
  inset: 0;
}

/* Generic text color helpers */

.text-primary {
  color: var(--color-primary-1);
}

.text-secondary {
  color: var(--color-secondary-1);
}

.text-tertiary {
  color: var(--color-tertiary-1);
}

.text-quaternary {
  color: var(--color-quaternary-1);
}

/* Override Twitter widget styles */
.twitter-tweet {
  margin: 0 auto !important;
  width: 100% !important;
  max-width: 550px !important;
}

.twitter-tweet iframe {
  margin: 0 auto;
}

// Placeholder styles with low specificity
blockquote:where(.twitter-tweet) {
  background-color: #fff;
  border-radius: 12px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 72px 16px 92px 16px;
  border: 1px solid rgb(207, 217, 222);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 12px;
    left: 16px;
    width: 48px;
    height: 48px;
    background-color: #eee;
    border-radius: 50%;
  }

  > p {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 8px;
  }

  a {
    color: rgb(0, 111, 214);
  }
}
