.Button {
  --size: 3.2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  border-radius: 4rem;
  background-color: transparent;

  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
  }

  &.theme-primary {
    background-color: var(--color-primary-1);

    &.outline {
      background-color: #fff;
      border: 1px solid var(--color-primary-1);
    }
  }

  &.theme-secondary {
    background-color: var(--color-secondary-1);

    &.outline {
      background-color: transparent;
      border: 1px solid var(--color-secondary-1);
    }
  }

  &.theme-tertiary {
    background-color: var(--color-tertiary-1);

    &.outline {
      background-color: #fff;
      border: 1px solid var(--color-tertiary-1);
    }
  }

  &.size-xs {
    --size: 1.4rem;
  }

  &.size-sm {
    --size: 2rem;
  }

  &.size-md {
    --size: 3.2rem;
  }

  &.size-lg {
    --size: 4.4rem;
  }

  &.outline {
    border: 1px solid #fff;
  }
}
