.RangeInput {
  --track-size: 2px;
  --knob-size: 8px;
  --radius: 8px;
  --foreground-color: var(--color-primary-1);

  input[type='range'] {
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
    height: var(--track-size);
    border-radius: var(--radius);
    display: block;
  }

  input::-webkit-slider-thumb {
    appearance: none;
    width: var(--knob-size);
    height: var(--knob-size);
    border-radius: var(--radius);
    background: var(--foreground-color);
    cursor: pointer;
    border: 0;
    outline: 0;
  }

  input::-moz-range-thumb {
    appearance: none;
    width: var(--knob-size);
    height: var(--knob-size);
    border-radius: var(--radius);
    background: var(--foreground-color);
    cursor: pointer;
    border: 0;
    outline: 0;
  }

  &.seamless {
    overflow: hidden;
    --track-size: 4px;
    --knob-size: 7px;
    --radius: 4px;
  }
}
