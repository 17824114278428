@use 'sass:math';

$line-width: 12px;
$line-width-2: ($line-width * 2);
$line-offset: math.hypot($line-width-2, $line-width-2) * -1;

.Input {
  display: grid;
  gap: 0.4rem;
  color: var(--color-secondary-1);
  text-align: left;

  label {
    span {
      color: var(--color-primary-1);
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='date'],
  input[type='number'] {
    padding: 1rem 1.2rem;
    font-size: 1.5rem;
    font-family: inherit;
    border: 1px solid var(--color-secondary-1);
    border-radius: var(--border-radius-1);
    background-color: #fff;
    width: 100%;

    &::placeholder {
      color: #93a3b2;
    }

    &[readonly] {
      background-color: #eee;
    }
  }

  &.loading {
    input[type='text'],
    input[type='email'],
    input[type='tel'],
    input[type='date'],
    input[type='number'] {
      background: repeating-linear-gradient(
        -45deg,
        #f9f9f9 0,
        #f9f9f9 $line-width,
        #fff $line-width,
        #fff $line-width-2
      );
      background-repeat: repeat;
      background-attachment: fixed;
      animation: moveLines 300ms linear infinite;
    }
  }
}

@keyframes moveLines {
  from {
    background-position-x: 0;
  }

  to {
    background-position: $line-offset;
  }
}
