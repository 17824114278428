.Textarea {
  display: grid;
  gap: 0.4rem;
  color: var(--color-secondary-1);
  text-align: left;

  label {
    span {
      color: var(--color-primary-1);
    }
  }

  textarea {
    padding: 1rem 1.2rem;
    font-size: 1.5rem;
    font-family: inherit;
    border: 1px solid var(--color-secondary-1);
    border-radius: var(--border-radius-1);
    background-color: #fff;
    width: 100%;

    &::placeholder {
      color: #93a3b2;
    }
  }
}
