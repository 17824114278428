@import '../../styles/variables';
@import '../../styles/util';

.FloatingPanel {
  position: fixed;

  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--spacing-2);

  transition:
    transform 300ms ease-in-out,
    opacity 300ms ease-in-out;
  transform: translateY(110%);
  pointer-events: none;
  opacity: 0;

  &.visible {
    transform: translateY(0%);
    opacity: 1;
  }

  & > * {
    pointer-events: all;
    max-width: 40rem;
    box-shadow: var(--box-shadow-2), var(--box-shadow-1), var(--box-shadow-5);
  }
}

@media only screen and (width >= $tablet-portrait) {
  .FloatingPanel {
    padding: var(--spacing-4);
  }
}
