.H {
  &.h1 {
    font-size: 2.6rem;
    line-height: 2.8rem;
  }

  &.h2 {
    font-size: 2.6rem;
    line-height: 2.8rem;
  }

  &.h3 {
    font-size: 2rem;
    line-height: 2.2rem;
  }

  &.h4 {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }

  &.h5 {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  &.h6 {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  &.w100 {
    font-weight: 100;
  }

  &.w200 {
    font-weight: 200;
  }

  &.w400 {
    font-weight: 400;
  }

  &.w500 {
    font-weight: 500;
  }

  &.w700 {
    font-weight: 700;
  }

  &.w900 {
    font-weight: 900;
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: right;
  }

  &.fat {
    font-weight: 400;
    font-family: var(--font-family-2);
    text-transform: uppercase;
    color: var(--color-secondary-1);
    line-height: 2.6rem;
    font-size: 2.6rem;
    text-wrap: balance;
  }

  &.theme-light {
    color: #fff;
  }

  &.theme-primary {
    color: var(--color-primary-1);
  }

  &.theme-secondary {
    color: var(--color-secondary-1);
  }
}
