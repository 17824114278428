@import '../../styles/variables';
@import '../../styles/util';

.Menu {
  background-color: #fff;
  position: fixed;
  inset: 0;
  height: 100vh;
  height: 100dvh;
  overscroll-behavior: contain;
  display: block;
  pointer-events: none;
  transform: translateX(100%);
  transition: transform 200ms ease-in-out;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    pointer-events: none;
  }

  &.open {
    pointer-events: all;
    transform: translateX(0);

    &::before {
      opacity: 1;
    }
  }
}

@media only screen and (width > $size-that-fits-hover-menu) {
  .Menu {
    display: none;
  }
}

.Menu nav {
  position: absolute;
  inset: 0;
  transform: translate3d(0%, 0, 0);
  transition: transform 200ms ease-in-out;
  width: 100%;
  height: 100dvh;
  overflow-y: auto;
  scroll-behavior: smooth;

  &:last-child {
    left: 100%;

    > ul {
      display: none;
      pointer-events: none;

      &.interactive {
        display: block;
        pointer-events: all;
      }
    }
  }

  &.expanded {
    transform: translate3d(-100%, 0, 0);
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-2) var(--spacing-2) 0 var(--spacing-2);

    > * {
      min-height: 4rem;
      display: flex;
      align-items: center;
    }
  }

  ul {
    list-style: none;
  }

  > ul {
    padding: var(--spacing-2);
  }

  ul a {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dfe0df;
    width: 100%;
    min-height: 4rem;
    color: var(--color-dark-1);
    font-size: 1.7rem;
    padding: 0.4rem 0;
  }

  ul ul > li > a {
    padding-left: var(--spacing-2);
  }

  ul ul ul > li > a {
    padding-left: var(--spacing-4);
  }

  ul ul ul ul > li > a {
    padding-left: var(--spacing-6);
  }

  li {
    width: 100%;
    position: relative;

    button {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  a[aria-current='page'] {
    color: var(--color-primary-1);
  }
}

.ButtonClose,
.ButtonLeft,
.ButtonRight {
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center right;
  mask-size: 1.6rem 1.6rem;
  mask-image: url(/svg/icon_arrow_left.svg);
  background-color: var(--color-dark-1);
  width: 4rem;
  height: 4rem;
  text-indent: -9999px;
}

.ButtonLeft {
  mask-position: center left;
}

.ButtonRight {
  mask-image: url(/svg/icon_arrow_right.svg);
}

.ButtonClose {
  height: 3.2rem;
  mask-image: url(/svg/icon_cross.svg);
  mask-size: 2.4rem;
}

@media only screen and (width > $size-that-fits-hover-menu) {
  .Menu {
    display: none;
  }
}
