.PlayButton {
  --size: 2.4rem;

  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  background-color: var(--color-dark-1);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  text-indent: -9999px;
  overflow: hidden;

  background-image: url(/svg/icon_play.svg);
  background-repeat: no-repeat;
  background-position: 14px center;
  background-size: 1.4rem;

  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
  }

  /* Theme variations */
  &.theme-dark {
    background-color: var(--color-dark-1);
  }

  &.theme-primary {
    background-color: var(--color-primary-1);
  }

  &.theme-light {
    background-color: #fff;
    background-image: url(/svg/icon_play_primary.svg);
  }

  /* Size variations */
  &.size-xs {
    --size: 1.8rem;
    background-position: 7px center;
    background-size: 0.6rem;
  }

  &.size-sm {
    --size: 2.4rem;
    background-position: 9px center;
    background-size: 0.8rem;
  }

  &.size-md {
    --size: 4rem;
    background-position: 14px center;
    background-size: 1.4rem;
  }

  &.size-lg {
    --size: 6rem;
    background-position: 20px center;
    background-size: 2.4rem;
  }

  &[aria-pressed='true'] {
    background-image: url(/svg/icon_pause.svg);
    background-position: center;
    background-size: 1rem;

    &.theme-light {
      background-image: url(/svg/icon_pause_primary.svg);
    }

    &.size-xs {
      background-size: 0.4rem;
    }

    &.size-sm {
      background-size: 0.6rem;
    }

    &.size-md {
      background-size: 1rem;
    }

    &.size-lg {
      background-size: 1.6rem;
    }
  }
}
