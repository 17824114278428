.Gridish {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-2);
  --cw: 400px;

  & > * {
    flex: 1 1 var(--cw);
  }
}

@media only screen and (width >= $tablet-portrait) {
  .Gridish {
    gap: var(--spacing-3);
  }
}

@media only screen and (width >= $tablet-landscape) {
  .Gridish {
    gap: var(--spacing-4);
  }
}
