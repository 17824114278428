@import '../../styles/variables';
@import '../../styles/util';

.Player {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 7%;

  @media only screen and (height >= 640px) {
    padding-bottom: 12%;
  }

  header {
    display: flex;
    width: 100%;
    padding: var(--spacing-2);
    gap: var(--spacing-2);
    position: absolute;
    inset: 0 0 auto 0;
    justify-content: flex-start;
    align-items: center;
  }

  picture {
    box-shadow: var(--box-shadow-1);
  }

  picture,
  img {
    aspect-ratio: 1 /1;
    border-radius: var(--border-radius-1);
    pointer-events: none;
  }

  h2 {
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 2.2rem;
    @include line-clamp(3);
  }

  p {
    font-size: 1.6rem;
    line-height: 2.2rem;
    @include line-clamp(2);

    small {
      font-size: 1.3rem;
    }
  }

  main {
    position: absolute;
    inset: 11% 0 auto 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
    max-width: 240px;
    margin: auto;
  }

  @media only screen and (height >= 600px) {
    main {
      max-width: 256px;
    }
  }

  @media only screen and (height >= 620px) {
    main {
      gap: var(--spacing-3);
      inset: 12% 0 auto 0;
      max-width: 276px;
    }
  }

  .PlayerItems {
    position: relative;
  }

  .PlayerItem {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
    margin: auto;
    justify-content: flex-start;
    opacity: 0;
    transform: translateX(-3rem);
    transition:
      opacity 300ms ease-in-out,
      transform 300ms ease-in-out;

    > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &.active {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.Icon {
  width: 1.4rem;
  height: 1.4rem;
  background-color: rgba(252, 226, 206, 0.3);
  border-radius: 1rem;
  position: relative;
  display: inline-block;
  margin: 0 4px 0 8px;
  transform: translateY(2px);

  &::before {
    content: '';
    position: absolute;
    inset: 2px;
    border-radius: 1rem;
    background-color: rgba(252, 226, 206, 0.5);
  }

  &::after {
    content: '';
    position: absolute;
    inset: 4px;
    border-radius: 1rem;
    background-color: rgba(252, 226, 206, 1);
  }
}
