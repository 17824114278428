@mixin create-gaps($prefix: '') {
  @for $i from 0 through 6 {
    .#{$prefix}-#{$i} {
      gap: calc(0.8rem * $i);
    }
  }

  @for $row from 0 through 6 {
    @for $column from 0 through 6 {
      .#{$prefix}-#{$row}-#{$column} {
        gap: calc(0.8rem * $row) calc(0.8rem * $column);
      }
    }
  }
}

@include create-gaps('gap');
@include create-gaps('gap-sm');

@media only screen and (width >= $tablet-portrait) {
  @include create-gaps('gap-md');
}

@media only screen and (width >= $tablet-landscape) {
  @include create-gaps('gap-lg');
}

@media only screen and (width >= $max-width-page) {
  @include create-gaps('gap-xl');
}
