.Error {
  color: var(--color-primary-1);
  font-size: 1.3rem;
}

.Label {
  color: var(--color-secondary-1);

  span {
    color: var(--color-primary-1);
  }
}
