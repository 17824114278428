@import '../../styles/variables';

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: var(--spacing-2);
  width: 100%;
  margin: 0 auto;
  padding: var(--spacing-2);
  max-width: var(--max-width-page);

  .MenuButton {
    width: 2.6rem;
    height: 2rem;
    text-indent: -9999px;
    overflow: hidden;
    background-image: url(/svg/icon_hamburger.svg);
    background-repeat: no-repeat;
    justify-self: flex-end;
  }
}

.Logo {
  mask-image: url(/svg/logo_groot_nieuws_radio.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: #fff;
  width: 64px;
  display: flex;
  height: 4rem;
  text-indent: -9999px;
  justify-self: flex-start;
}

.HeaderBody {
  display: flex;
  gap: 2.4rem;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;

  strong {
    color: #fff;
    font-size: 1.4rem;
    padding-right: 0.8rem;
    display: none;
  }

  a {
    display: none;
  }
}

.Search {
  display: flex;
  width: 100%;
  gap: 0;

  input[type='text'] {
    border-radius: var(--spacing-1) 0 0 var(--spacing-1);
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
  }

  button {
    flex-shrink: 0;
    border-radius: 0 var(--spacing-1) var(--spacing-1) 0;
  }
}

@media only screen and (width >= 648px) {
  .Header {
    justify-content: flex-end;
  }

  .HeaderBody {
    gap: 0.8rem;

    a {
      display: flex;
    }
  }

  .Search {
    width: auto;
    justify-self: flex-end;
  }
}

@media only screen and (width >= $tablet-portrait) {
  .Header {
    padding: var(--spacing-2) var(--spacing-3);
    gap: var(--spacing-3);
  }

  .Logo {
    height: 5.6rem;
    width: 140px;
  }

  .Search {
    margin-left: var(--spacing-2);
  }
}

@media only screen and (width >= $tablet-landscape) {
  .HeaderBody {
    strong {
      display: flex;
    }
  }
}

@media only screen and (width >= $max-width-page) {
  .Header {
    padding: var(--spacing-2) var(--spacing-4);
  }

  .Logo {
    height: 6.4rem;
  }
}

@media only screen and (width > $size-that-fits-hover-menu) {
  .MenuButton {
    display: none;
  }
}
