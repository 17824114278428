@import '../../styles/variables';
@import '../../styles/util';

.Card {
  background-color: #fff;
  box-shadow: var(--box-shadow-1);
  border-radius: var(--border-radius-1);
  position: relative;
  overflow: hidden;

  &.hasLabel {
    padding-top: 4.8rem;
    overflow: visible;
  }

  h2 {
    font-size: 2.2rem;
    line-height: 2.4rem;
    font-weight: 400;
  }

  p {
    font-size: 1.4rem;
  }
}

.CardImage {
  img {
    aspect-ratio: 336 / 224;
    height: auto;
  }
}

.CardBody {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  padding: var(--spacing-2);
  overflow: hidden;
  text-align: start;
}

.CardLabel {
  background-color: var(--color-primary-1);
  color: #fff;
  position: absolute;
  top: var(--spacing-2);
  left: calc(var(--spacing-1) * -1);
  padding: 0.4rem var(--spacing-2);
  border-radius: var(--border-radius-1);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.4rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
}

@media only screen and (width >= $tablet-portrait) {
  .CardBody {
    padding: var(--spacing-3);
  }

  .CardLabel {
    left: calc(var(--spacing-1) * -2);
  }
}
